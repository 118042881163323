<template>
    <div class="modal fade show modal-style" aria-labelledby="addNewCardTitle">
        <div class="modal-dialog modal-dialog-centered" ref="modalRef">
            <div class="modal-content">
              <div class="row">
                <div class="col-12">
                  <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                    <h1 class="mb-0">{{ title }} <small v-if="pendingOutTimeData">Last In Time {{ pendingOutTimeData.calendar_date }} | {{ pendingOutTimeData.in_time }}</small>  </h1>
                    <button
                        @click="onClickClose"
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                  </div>
                </div>
              </div>

                <hr class="mt-0 mb-0">

                <div class="pb-2">
                    <div class="row py-1 mx-auto d-flex align-items-center">
                        <div class="col-12" v-if="!pendingOutTimeData || (pendingOutTimeData && !isInTime)">
                          <div class="row">
                             <div class="col-md-6 col-12">
                               <div class="date">
                                 <span class="highlight">{{ currentTime }}</span>
                               </div>
                             </div>
                            <div class="col-md-6 col-12">
                              <label class="form-label">Remarks</label>
                              <textarea
                                  class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                  tabindex="1"
                                  type="text"
                                  placeholder=""
                                  v-model="formData.remarks">
                            </textarea>
                            </div>
                          </div>
                      </div>

                      <div class="col-12" v-if="pendingOutTimeData && isInTime">
                        <div class="row">
                          <div class="col-12 col-md-6">
                            <label class="form-label">Last Out Time</label>
                            <input
                                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                tabindex="1"
                                type="datetime-local"
                                placeholder=""
                                v-model="formData.out_time"
                            >
                          </div>
                          <div class="col-12 col-md-6">
                            <label class="form-label">Remarks</label>
                            <textarea
                                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                tabindex="1"
                                type="text"
                                placeholder=""
                                v-model="formData.remarks">
                            </textarea>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 pt-2">
                        <button :disabled="disable" @click="handleSave" class="btn btn-primary btn-block waves-effect waves-float waves-light">
                          Submit
                        </button>
                      </div>

                    </div>
                </div>
            </div>
        </div>
        <Loader v-if="loader"/>
    </div>
</template>

<script setup>
import Loader from "@/components/atom/LoaderComponent";
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";
import {inject, onMounted, onUnmounted, reactive, ref} from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import useDate from '@/services/utils/day'

const props = defineProps({
    title: {
        type: String,
        default: 'In Time'
    },
    type: {
        type: String,
        default: 'all'
    },
    isInTime: {
        type: Boolean,
        default: true
    },
    attendanceId: {
      type: Number,
      default: null
    },
    pendingOutTimeData: {
        type: Boolean,
        default: null
    },
})

const emit = defineEmits(['fetchCurrentAttendance']);

const {
  createAttendance,
  updateAttendance
} = handleHrPayroll()

const $store = useStore();
const $route = useRoute();
const loading = ref(false);
const disable = ref(false)
const loader = ref(false);
const showError = inject('showError');
const showSuccess = inject('showSuccess');
let currentTime = ref('')
const { formatDateTime } = useDate()
let timer = null;
const attendanceData = {}
let formData = ref({
   out_time: '',
   remarks: '',
})

const handleSave = async () => {
    loading.value = true;
    disable.value = true;

    if (navigator.geolocation) {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0,
          });
        });

        attendanceData.latitude = position.coords.latitude;
        attendanceData.longitude = position.coords.longitude;
      } catch (err) {
        console.error('Geolocation error:', err);
      }
    }

    attendanceData.company_id = $route.params.companyId;
    attendanceData.is_in_time =  props.isInTime;
    attendanceData.is_out_time_exist =  true;
    attendanceData.remarks = formData.value.remarks;
    if (props.pendingOutTimeData) {
      attendanceData.is_out_time_exist =  false;
    }

    if (attendanceData.is_in_time && attendanceData.is_out_time_exist) {
      await saveAttendanceInfo()
      return
    }

    attendanceData.out_time = formData.value.out_time ? formatDateTime(formData.value.out_time) : null;
    await updateAttendanceInfo()
}

const saveAttendanceInfo = () => {
  createAttendance(attendanceData, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
        showSuccess(res.message)
        emit('fetchCurrentAttendance')
        onClickClose()
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        loader.value = false
        disable.value = false
      })
}

const updateAttendanceInfo = () => {
  updateAttendance(attendanceData, props.attendanceId, getQuery())
      .then(res => {
        if (!res.status) {
          showError(res.message)
          return
        }
        showSuccess(res.message)
        emit('fetchCurrentAttendance')
        onClickClose()
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
         loader.value = false
         disable.value = false
      })
}

const onClickClose = () => {
    $store.state.isModalOpenThree = false;
}

function getQuery() {
    return `?company_id=${$route.params.companyId}`;
}

const updateTime = () => {
  const date = new Date();
  currentTime.value = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
};

onMounted(() => {
  updateTime();
  timer = setInterval(updateTime, 1000);

  if(props.pendingOutTimeData){
     formData.value.out_time = props.pendingOutTimeData.in_datetime
  }
});

onUnmounted(() => {
  clearInterval(timer);
});

</script>

<style scoped>
.modal-style {
    display: block; 
    background: #1c1d1c33
}
.roles-segment {
    margin-top: -13px;
}
.date {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.highlight {
  color: black;
  font-weight: bold;
}
</style>
