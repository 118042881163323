<template>
  <div class="col-sm-12">
    <div class="card">

        <div class="row my-2">
          <div class="col-12">
            <div class="date">
              <span class="highlight">{{ currentDay }}</span> | {{ currentDate }}
            </div>
          </div>
        </div>

        <div class="row justify-content-center py-1 px-1 g-2">
          <div class="col-6 col-md-3">
            <div class="custom-card">
                <div class="text-center border rounded">
                  <div class="label pt-1"><i class="fa-solid fa-arrow-right-to-bracket"></i>  In Time</div>
                  <div class="time-box" v-if="inTime"> {{ inTime }}</div>
                  <div class="pending-box" v-else @click="onOpenInTimeModal"><i class="fa fa-fingerprint"></i></div>
              </div>
            </div>
          </div>

          <div class="col-6 col-md-3">
            <div class="custom-card">
            <div class="text-center  border rounded" >
              <div class="label pt-1"> Out Time <i class="fa-solid fa-arrow-right-to-bracket"></i></div>
              <div class="time-box" v-if="outTime"> {{ outTime }}</div>
              <div
                  class="pending-box"
                  v-else
                  :class="{ 'disable-icon': !inTime }"
                  :style="{ cursor: inTime ? 'pointer' : 'not-allowed' }"
                   @click="handleOutTimeClick"
                >
                   <i class="fa fa-fingerprint"></i>
              </div>
            </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <InAttendance
        v-if="store.state.isModalOpenThree"
        :title="modalTitle"
        :isInTime="isModalIsInTime"
        :pendingOutTimeData="pendingOutTimeData"
        :attendanceId="attendanceId"
        @fetchCurrentAttendance="fetchCurrentAttendance"
    />
</template>

<script setup>
import figureFormatter from "@/services/utils/figureFormatter";
import {onMounted, ref} from "vue";
import InAttendance from '@/components/atom/attendance/InAttendance.vue'
import {useStore} from "vuex";
import handleHrPayroll from "@/services/modules/hrPayroll/hrPayroll";
import {useRoute} from "vue-router";

const item_key = ref('income');
const {commaFormat} = figureFormatter()
const store = useStore()
const $route = useRoute();

let currentDate = ref('')
let currentDay = ref('')
let inTime = ref('')
let outTime = ref('')
let modalTitle = ref('')
let isModalIsInTime = ref(true)
let pendingOutTimeData = ref(null)
let attendanceId = ref(null)

const getCurrentDate = () => {
  const date = new Date();
  const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

  currentDay.value = days[date.getDay()];
  currentDate.value = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  }).replace(/ /g, '-').toUpperCase();
}
const {
  fetchCurrentAttendanceInfo,
} = handleHrPayroll()

function getQuery() {
  return `?company_id=${$route.params.companyId}`;
}

const onOpenInTimeModal = () => {
  modalTitle.value = 'In Time'
  isModalIsInTime.value = true
  store.state.isModalOpenThree = true;
}

const handleOutTimeClick = () => {
  if (!inTime.value) {
    return
  }
  onOpenOutTimeModal()
}

const onOpenOutTimeModal = () => {
  modalTitle.value = 'Out Time'
  isModalIsInTime.value = false
  store.state.isModalOpenThree = true;
}

const fetchCurrentAttendance = () => {
  const fetchAttendance = fetchCurrentAttendanceInfo(getQuery());
  fetchAttendance.then(res => {
    attendanceId.value = null;
    pendingOutTimeData.value = null

    if (!res.data){
      return
    }

    if (res.data.pending_out_time_data){
      pendingOutTimeData.value = res.data.pending_out_time_data
      attendanceId.value = res.data.pending_out_time_data.id
    }

    if (res.data.today_attendance_record){
      inTime.value = res.data.today_attendance_record.in_time
      outTime.value = res.data.today_attendance_record.out_time
      attendanceId.value = res.data.today_attendance_record.id
    }
  });
}

onMounted(() => {
  getCurrentDate()
  fetchCurrentAttendance()
})

</script>


<style>
.date {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}
.highlight {
  color: black;
  font-weight: bold;
}
.label {
  color: blue;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 18px;
}
.time-box, .pending-box {
  font-size: 20px;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}
.time-box {
  color: blue;
  font-size: 60px;
  font-weight: bold;
}
.pending-box {
  color: blue;
  font-size: 60px;
  font-weight: bold;
}
.disable-icon{
  color: grey !important;
  cursor: not-allowed;
}
.custom-card{
  background: #0000ff14;
  border-radius: 10px;
}
@media screen and (max-width: 768px) {
  .time-box, .pending-box {
    font-size: 30px;
  }

  .label {
    font-size: 14px;
  }

  .date {
    font-size: 14px;
  }

  .highlight {
    font-size: 14px;
  }
}
</style>